// Here you can add other styles
.body {
    font-family: "IBM Plex Sans", sans-serif;
    color: #495057;
}

canvas {
    height: 100 !important;
}

div {
    font-family: "IBM Plex Sans", sans-serif;
    color: #495057;
}

table {
    color: #495057 !important;
    font-size: 14px;
}

.MuiButton-root {
    text-transform: unset !important;
    border-radius: 30px !important;
}

.btn-primary {
    background-color: #FFD91D !important;
    box-shadow: 0 2px 6px 0 rgb(81 86 190 / 50%) !important;
}

.btn-primary:hover {
    color: #000;
    background-color: #FFD91D;
    border-color: #414598;
}

.btn-primary,
.btn-primary:focus {
    color: #000;
    background-color: #FFD91D;
    border-color: #414598;
    -webkit-box-shadow: 0 0 0 0.15rem rgb(107 111 200 / 50%);
    box-shadow: 0 0 0 0.15rem rgb(107 111 200 / 50%);
}

.bg-primary {
    background-color: #fedf02 !important;
}

.cursor-pointer {
    &:hover {
        cursor: pointer;
    }
}

.btn-link {
    font-weight: 400 !important;
    color: #FFD91D !important;
    text-decoration: none !important;
}

.btn-link:hover {
    color: #FFD91D !important;
    text-decoration: underline !important;
}

// Breadcrumb
.breadcrumb-div {
    background-color: white;
    color: #495057;
    height: 56px;
}

.breadcrumb-div a {
    text-decoration: none;
    pointer-events: none;
    cursor: default;
    color: #495057;
}

.breadcrumb-item {
    font-size: 14px !important;

    &.active {
        color: #74788d !important;
        font-size: 14px !important;
    }
}

.breadcrumb-title {
    font-size: 18px;
    font-weight: 600;
    color: #495057;
}

.text-error {
    color: rgb(255, 80, 80);
}

.cancel-search:hover {
    background-color: #dddddd;
}

// Mui
.MuiFilledInput-root {
    border-radius: 2px !important;

    &::before {
        border-bottom-style: none !important;
    }
}

.MuiFilledInput-root.Mui-error {
    box-shadow: rgb(255 80 80) 0 0 0 1px !important;
}

.MuiGrid-root.MuiGrid-item {
    padding-top: 14px !important;
}

.MuiInputBase-adornedEnd {
    &::after {
        border-bottom: none !important;
    }
}

.input-label {
    transform: translate(14px, 9px) scale(1) !important;

    &.Mui-focused {
        transform: translate(14px, -9px) scale(0.75) !important;
    }

    &.MuiFormLabel-filled {
        transform: translate(14px, -9px) scale(0.75) !important;
    }
}

.input-select {
    &.Mui-error {
        .MuiSelect-select {
            border-color: #d32f2f !important;
        }
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.w-5 {
    width: 5% !important;
}

.w-10 {
    width: 10% !important;
}

.w-15 {
    width: 15% !important;
}

.w-20 {
    width: 20% !important;
}

.w-25 {
    width: 25% !important;
}

.w-30 {
    width: 30% !important;
}

.w-40 {
    width: 40% !important;
}

.w-50 {
    width: 50% !important;
}

.pt-2 {
    padding-top: 20px !important;
}

.form-details-label {
    background: rgba(70, 104, 229, 0.1);
    padding: 8px;
    color: rgba(62, 62, 62, 1);
    font-size: 13px;
    font-weight: 500;
}

.form-details-value {
    padding-left: 8px;
    color: rgba(62, 62, 62, 1);
    font-weight: 400;
    opacity: 0.8;
}

#main-menu {
    a {
        color: #545a6d;
        font-weight: 500;

        svg {
            color: #545a6d;
        }
    }

    .active {
        color: #ff00ff;

        svg {
            color: #ff00ff;
        }
    }
}

.sidebar-nav .nav-link {
    padding: 0.62rem 1.5rem;
    font-size: 0.9rem;
    color: #545a6d;
}

.nav-icon-feather {
    width: 16px;
    margin-right: 10px;
}

.MuiFormHelperText-root {
    margin-top: -10px !important;
}

.Mui-error.MuiFormHelperText-root {
    width: fit-content !important;
    padding: 0 5px !important;
    background-color: white !important;
    z-index: 9 !important;
}

// sidebar toggle
.nav-group {
    &.show {
        background: none !important;

        > .nav-group-toggle::after {
            transform: rotate(90deg) !important;
        }
    }
}

// table
.table-title {
    padding: 12px 30px 12px 12px !important;
}

.table-content {
    padding: 0.75rem 0.75rem !important;
}

// icon button
.icon-btn:hover {
    color: white !important;
    background-color: #343a40 !important;
}

.icon-btn:focus {
    box-shadow: 0 0 0 0.15rem rgb(52 58 64 / 50%);
}

// footer
.footer {
    height: 60px;
    font-size: 11.2px;
    color: #74788d;
    padding: 20px calc(20px / 2);
}

.header-sticky {
    border-bottom: none;
}

.MuiTypography-root {
    margin-bottom: 0px !important;
}

.hover-button {
    &:hover {
        border: none !important;
        background-color: #5156be !important;

        .MuiTypography-root {
            color: #fff;
        }
    }
}

.icon-button {
    border-color: #5156be !important;
    background-color: #5156be !important;
    color: white !important;
    box-shadow: 0 2px 6px 0 rgb(81 86 190 / 50%);
}

.icon-button-grey {
    border-color: transparent !important;
    background-color: rgba(52, 58, 64, 0.1) !important;
    color: #343a40 !important;
    width: 32px;
    height: 32px;
    font-size: 16px;
}

.icon-button-red {
    border-color: transparent !important;
    background-color: rgba(255, 0, 0, 0.1) !important;
    color: red !important;
    width: 32px;
    height: 32px;
    font-size: 16px;
}

.status-tag-on-going {
    padding-left: 2%;
    padding-right: 2%;
    font-size: 11px !important;
    border-radius: 50rem !important;
    color: #ffbf53;
    background-color: rgba(255, 191, 83, 0.18);
    width: fit-content;
}

.status-tag-completed {
    padding-left: 2%;
    padding-right: 2%;
    width: fit-content;
    font-size: 11px !important;
    border-radius: 50rem !important;
    color: #5156be;
    background-color: rgba(81, 86, 190, 0.18);
}

.status-tag-approved {
    padding-left: 2%;
    padding-right: 2%;
    width: fit-content;
    font-size: 11px !important;
    border-radius: 50rem !important;
    color: #2ab57d;
    background-color: rgba(42, 181, 125, 0.18);
}

.status-tag-rejected {
    padding-left: 2%;
    padding-right: 2%;
    width: fit-content;
    font-size: 11px !important;
    border-radius: 50rem !important;
    color: #ff6482;
    background-color: rgba(255, 100, 130, 0.18);
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.badge-soft-primary {
    color: #5156be;
    background-color: rgba(81, 86, 190, 0.18);
}

.badge-soft-dark {
    color: #343a40;
    background-color: rgba(52, 58, 64, 0.18);
}

.rounded-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
}

.font-size-11 {
    font-size: 11px !important;
}

.text-muted {
    color: #74788d;
}

.circle-number {
    text-align: center;
    padding-top: 2px;
    content: counter(item);
    font-size: 10px !important;
    color: #343a40;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #eaebec;
}

.accordian-submission-details {
    .MuiAccordionSummary-content {
        margin: 0px;
    }

    .MuiPaper-root {
        &::before {
            height: 0px !important;
        }
    }
}

.active-infleuncer-details-tab {
    border-radius: 20px;
    background-color: #fff;
    border: solid 1px #e9e9ef;
    box-shadow: 2px 2px 6px #e9e9ef;

    div {
        color: #f73fff;
    }

    svg {
        color: #f73fff;
    }
}

.influencer-campaign-pending {
    border-radius: 50rem !important;
    padding-right: 0.6em;
    padding-left: 0.6em;
    color: #343a40;
    background-color: rgba(52, 58, 64, 0.18);
    font-size: 11px !important;
}

.influencer-campaign-approved {
    border-radius: 50rem !important;
    padding-right: 0.6em;
    padding-left: 0.6em;
    color: #2ab57d;
    background-color: rgba(42, 181, 125, 0.18);
    font-size: 11px !important;
}

.influencer-campaign-rejected {
    border-radius: 50rem !important;
    padding-right: 0.6em;
    padding-left: 0.6em;
    color: #ff6482;
    background-color: rgba(255, 100, 130, 0.18);
    font-size: 11px !important;
}

.gallery {
    margin: 5px;
    border: 1px solid #ccc;
    float: left;
    width: 150px;
    position: relative;
}

.gallery img {
    width: 100%;
    height: 148px;
    // height: auto;
}

.gallery .desc {
    padding: 10px;
    font-size: 12px;
    text-align: left;
    word-wrap: break-word; 
    overflow-wrap: break-word;
}

.gallery .gallery-close-icon {
    position: absolute !important;
    top: 0;
    right: 0;
}

.image-container {
    position: relative;
    width: 25%;
    padding-top: 5px;
}

.comment-date {
    display: table;
    color: #d6d6d6;
    background: rgb(132 132 132 / 50%);
    padding: 1px 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 5px;
    color: black;
}

@media only screen and (max-width: 600px)  {
    .card-live-report {
        width: 100% !important;
    }
}

.icon-button-green {
    border-color: transparent !important;
    background-color: rgba(0,128,0, 0.1) !important;
    color: green !important;
    width: 32px;
    height: 32px;
    font-size: 16px;
}

/* Hide close button in modal header */
.modal-header .btn-close {
    display: none;
}

/* Instagram embed */
.instagram-embed-wrapper .rsme-instagram-embed .instagram-media {
    width: 530px;
    max-width: 530px;
}

.instagram-embed-wrapper .rsme-instagram-embed .instagram-media:not(:last-child) {
    display: none !important;
}